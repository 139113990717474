/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ViewPreviousAssessmentGrid from "./ViewPreviousAssessmentGrid";
import $ from "jquery";
import backArrowIcon from "../../images/icon-back-arrow.png";
import downloadIcon from "../../images/icon-download.png";
import loderIcon from "../../images/loader-strokes.gif";
import request from "../../services/Service";
import { Typeahead } from "react-bootstrap-typeahead";
import i18n from "i18next";
import langKeys from "../../Utils/LangKeys";
import { withTranslation } from "react-i18next";
import { BaseURL } from "../../Constant";
import Capability from "../../Master/PMMConfiguration/Capability/Capability";

var recordCount = 0;

class ViewPreviousAssessmentSubmitted extends React.Component {
  constructor(props) {
    super(props);
    try {
      recordCount = 0;
      this.state = {
        prevAssessmentGridData: [],
        disableCompare: true,
        showComparePopup: false,
        headerValue: "",
        lstDeal: [],
        lstIndustry: [],
        lstRegion: [],
        lstCapability: [],
        lstCountry: [],
        lstTower: [],
        lstServiceComp: [],
        lstDlvryLoc: [],
        DealValue: [],
        ProcessValue:[],
        lstprocess:[],
        IndustryValue: "",
        RegionValue: [],
        MainOfferingValue: [],
        lstMainoffering:[],
        OGIndustry: "",
        OG: "",
        Industry: "",
        CapabilityValue: [],
        CountryValue: { label: i18n.t("all"), value: "0" },
        TowerValue: [],
        ServiceCompValue: [],
        DlvryLocValue: { label: i18n.t("all"), value: "0" },
        eblNxtBTn: false,
        Confirm: false,
        message: "",
        DraftID: 0,
        isReport: false,
        showLoaderFlag: false,
        OMIDNewValue: "",
        clientName: "",
        Market: "",
        MainOffering: "",
        Offering_SSG: "",
        SubOffering: "",
        defaultProcessName: [],
        Process_Id: 0,
        MainOfferingid: 0,
        subOfferingId: 0,
        Industry_Id: 0,
        ClientId:0,
        Marketid: 0,
        OMID: 0,
        ProcessName: [],
        City_Id: 0,
      };
      this.HandleDropdownValues = this.HandleDropdownValues.bind(this);
      this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
      this.handleOMIDChange = this.handleOMIDChange.bind(this);
      this.ValidateOMID = this.ValidateOMID.bind(this);
      this.onDealChange = this.onDealChange.bind(this);
      this.SelectMarket =this.SelectMarket.bind(this);
      this.SelectProcess = this.SelectProcess.bind(this);
      this.SelectTower = this.SelectTower.bind(this);
      this.SelectMainOffering = this.SelectMainOffering.bind(this);


    } catch (error) {
      console.log("Error Encountered");
    }
  }

  componentDidMount() {
    this.HandleDropdownValues();

    try {
      //window.tfo_event("PMM-VSA-1", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
      $("#loader-div").show();

      let marketData ={
        UserName: this.props.enterpriseId,
        ClientNumber:this.state.ClientId,
        GroupID: this.props.groupId,
       LanguageID: this.props.languageId};
       request("/Common/GetMarket", "POST", marketData)
       .then((response) => {
        console.log("exce",response)
         response.length > 0
           ? this.setState({ lstMarket: response })
           : this.setState({ lstMarket: [] });
         $("#loader-div").hide();
       })
       .catch((error) => {
         console.log(error);
         $("#loader-div").hide();
       });

   

      let dealData = {
        UserName: this.props.enterpriseId,
        DealId: 0,
        PermissionLevel: "3,2,1",
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/GetDealBasedOnUserAccess", "POST", dealData)
        .then((response) => {
          response.length > 0
            ? this.setState({ lstDeal: response })
            : this.setState({ lstDeal: [] });
          $("#loader-div").hide();
        })
        .catch((error) => {
          console.log(error);
          $("#loader-div").hide();
        });

      let userData = {
        UserName: this.props.enterpriseId,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/GetReportAccessByUser", "POST", userData)
        .then((response) => {
          response.Table.length > 0
            ? this.setState({ isReport: response.Table[0].IsReport })
            : this.setState({ isReport: "" });
          $("#loader-div").hide();
        })
        .catch((error) => {
          console.log(error);
          $("#loader-div").hide();
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  GetAssessmentDetails() {
    try {
      console.log("Process_Id value:", this.state.Process_Id);
      console.log(this.state.OMIDNewValue,"omid")
     // console.log(this.state.clientName,"clientd")
      if ((this.state.OMIDNewValue === "" || this.state.OMIDNewValue == null) && 
            (this.state.DealValue === "" || this.state.DealValue == null || this.state.DealValue.length === 0))
             {
            alert(i18n.t("pleaseselectOMID//client"));
            return false;
        }
 
      var objThis = this;
      this.setState({ showComparePopup: false, showLoaderFlag: true });
      var DealID = this.state.ClientId;
      var RegionID = this.state.Marketid;

      var CountryID = this.state.OMID;

      var CapabilityID = this.state.MainOfferingid;
      var TowerID = this.state.subOfferingId;

      var IndustryID = this.state.Industry_Id;
      var SCID = this.state.Process_Id;
      var SearchedString = "";
//log scid
      console.log("SCID:", SCID);
      let viewAssessmentData = {
        UserName: this.props.enterpriseId,
        PMMProcessID: 0,
        DealID: DealID,
        RegionID: RegionID,
        CountryID: CountryID,
        CapabilityID: CapabilityID,
        TowerID: TowerID,
        IndustryID: IndustryID,
        SCID: SCID,
        SearchedString: SearchedString,
        PermissionLevel: "3,2,1",
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
       // SubOfferingIds:this.props.subofferingIds,
        SubOfferingIds:this.state.SubOfferingIds,
      };
      console.log("viewAssessmentData:", viewAssessmentData);
      request(
        "/BOI/ViewAssessmentDetailsBasedOnUserAccess",
        "POST",
        viewAssessmentData
      )
        
        .then((response) => {
          response.Table == null
            ? (recordCount = 0)
            : (recordCount = response.Table.length);
          objThis.setState({
            prevAssessmentGridData: response.Table,
            showLoaderFlag: false,
          });
          
        })
        .catch((error) => {
          console.log(error);
          objThis.setState({ showLoaderFlag: false });
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  // SelectDeal(val) {
  //   try {
  //     $("#loader-div").show();
  //     this.setState({
  //       showComparePopup: false,
  //       lstServiceComp: [],
  //       ServiceCompValue: [],
  //       lstCapability: [],
  //       CapabilityValue: [],
  //       lstTower: [],
  //       TowerValue: [],
  //     });
  //     if (val.length != 0) {
  //       this.setState({
  //         DealValue: val,

  //         prevAssessmentGridData: [],
  //       });

  //       let ogData = {
  //         UserName: this.props.enterpriseId,
  //         DealId: val[0].value,
  //         GroupID: this.props.groupId,
  //         LanguageID: this.props.languageId,
  //       };
  //       request("/Common/GetOGBasedOnDeal", "POST", ogData)
  //         .then((response) => {
  //           this.setState({ OGIndustry: response[0] });
  //           $("#loader-div").hide();
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           $("#loader-div").hide();
  //         });

  //       let capabilityData = {
  //         UserName: this.props.enterpriseId,
  //         ID: 0,
  //         DealId: val[0].value,
  //         DLId: 0,
  //         PermissionLevel: "3,2,1",
  //         GroupID: this.props.groupId,
  //         LanguageID: this.props.languageId,
  //       };
  //       request(
  //         "/Common/GetCapabilityBasedOnUserAccess",
  //         "POST",
  //         capabilityData
  //       )
  //         .then((response) => {
  //           response.length > 0
  //             ? this.setState({ lstCapability: response })
  //             : this.setState({ lstCapability: [] });
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //       this.setState({
  //         lstRegion: [],
  //         RegionValue: [],
  //       });

  //       request(
  //         "/MasterScreens/GetRegionBasedOnDeal?DealId=" +
  //           val[0].value +
  //           "&Flag=1&GroupID=" +
  //           this.props.groupId +
  //           "&LanguageID=" +
  //           this.props.languageId,
  //         "GET"
  //       )
  //         .then((response) => {
  //           if (response.length > 0) {
  //             this.setState({
  //               RegionValue: [{ label: i18n.t("all"), value: "0" }],
  //             });
  //             this.setState({ disableCompare: false });
  //           }
  //           this.setState({ lstRegion: response });
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });

  //       let dealData = {
  //         UserName: this.props.enterpriseId,
  //         DeliveryLocationId: 0,
  //         DealId: val[0].value,
  //         PermissionLevel: "3,2,1",
  //         GroupID: this.props.groupId,
  //         LanguageID: this.props.languageId,
  //       };
  //       request("/Common/DLDropDownBasedOnUserAccess", "POST", dealData)
  //         .then((response) => {
  //           response.length > 0
  //             ? this.setState({ lstDlvryLoc: response })
  //             : this.setState({ lstDlvryLoc: [] });
  //           $("#loader-div").hide();
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } else {
  //       this.ClearFilter();
  //       $("#loader-div").hide();
  //     }
  //   } catch (error) {
  //     console.log("Error Encountered");
  //   }
  // }

  // SelectIndustry(val) {
  //   try {
  //     this.setState({ IndustryValue: val });
  //     this.setState({ showComparePopup: false });
  //   } catch (error) {
  //     console.log("Error Encountered");
  //   }
  // }

  // SelectRegion(val) {
  //   try {
  //     this.setState({
  //       RegionValue: val,
  //       prevAssessmentGridData: [],
  //     });
  //     this.setState({ showComparePopup: false });
  //     val[0].label == i18n.t("all")
  //       ? this.setState({ disableCompare: false })
  //       : this.setState({ disableCompare: true });

  //     var dealId = this.state.DealValue;

  //     if (val.length != 0) {
  //       request(
  //         "/MasterScreens/GetCountryBasedOnRegion?DealId=" +
  //           dealId[0].value +
  //           "&RegionId=" +
  //           val[0].value +
  //           "&GroupID=" +
  //           this.props.groupId +
  //           "&LanguageID=" +
  //           this.props.languageId,
  //         "GET"
  //       )
  //         .then((response) => {
  //           this.setState({ lstCountry: response });
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } else {
  //       this.setState({
  //         RegionValue: [],
  //         CapabilityValue: [],
  //         TowerValue: [],
  //         ServiceCompValue: [],
  //       });
  //       $("#loader-div").hide();
  //     }
  //   } catch (error) {
  //     console.log("Error Encountered");
  //   }
  // }

  // SelectCapability(val) {
  //   try {
  //     $("#loader-div").show();
  //     this.setState({ CapabilityValue: val, prevAssessmentGridData: [] });
  //     this.setState({ showComparePopup: false });

  //     if (val.length != 0) {
  //       let towerData = {
  //         UserName: this.props.enterpriseId,
  //         TowerID: 0,
  //         DealId: this.state.DealValue[0].value,
  //         CapabilityId: val[0].value,
  //         DeliveryLocationId: 0,
  //         DLId: 0,
  //         PermissionLevel: "3,2,1",
  //         GroupID: this.props.groupId,
  //         LanguageID: this.props.languageId,
  //       };
  //       request("/Common/GetTowerBasedOnUserAccess", "POST", towerData)
  //         .then((response) => {
  //           this.setState({
  //             TowerValue: [],
  //             lstTower: response,
  //             lstServiceComp: [],
  //             ServiceCompValue: [],
  //             eblNxtBTn: false,
  //           });
  //           $("#loader-div").hide();
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           $("#loader-div").hide();
  //         });
  //     } else {
  //       this.setState({
  //         CapabilityValue: [],
  //         TowerValue: [],
  //         ServiceCompValue: [],
  //       });
  //       $("#loader-div").hide();
  //     }
  //   } catch (error) {
  //     console.log("Error Encountered");
  //   }
  // }

  // SelectCountry(val) {
  //   try {
  //     this.setState({ CountryValue: val });
  //     val[0].label == i18n.t("all") &&
  //     this.state.RegionValue[0].label == i18n.t("all")
  //       ? this.setState({ disableCompare: false })
  //       : this.setState({ disableCompare: true });
  //     this.setState({ showComparePopup: false });
  //   } catch (error) {
  //     console.log("Error Encountered");
  //   }
  // }


  handleOMIDChange(e) {
    if (e.target.value == "") {
      this.setState({
        OMIDNewValue: "",
        clientName: "",
        Market: "",

        MainOffering: "",
        Offering_SSG: "",
        SubOffering: "",
        defaultProcessName: [],
        ProcessName:"",
        lstprocess:[],
        ProcessValue:"",

      });
    } else {
      this.setState({
        OMIDNewValue: e.target.value,
       
      });
      
    }
  }

  ValidateOMID() {
        fetch(
          BaseURL + "/Common/GetOMIdDetails1l?OMID=" + this.state.OMIDNewValue,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.setState({
              clientName: data[0].client_name_1,
              Market: data[0].market_name,
              MainOffering: data[0].main_offering_name,
              Offering_SSG: data[0].offering_ssg_name,
              SubOffering: data[0].sub_offering_name,
              City_Id: data[0].city_id,
              ClientId:data[0].client_number,
              Marketid: data[0].market_id,
              OMID: data[0].om_master_id,
              Process_Id: data[0].taxonomy_id,
              MainOfferingid: data[0].main_offering_id,
              subOfferingId: data[0].sub_offering_id,
              Industry_Id: data[0].offering_ssg_id,
              DealValue: [{label: data[0].client_name_1,value: data[0].client_name_1}],
              MarketValue:[{label: data[0].market_name,value: data[0].market_name}],
               MainOfferingValue:[{label: data[0].main_offering_name,value: data[0].main_offering_name}],
               CapabilityValue:[{label: data[0].offering_ssg_name,value: data[0].offering_ssg_name}],
               TowerValue:[{label: data[0].sub_offering_name,value: data[0].sub_offering_name}],
               ProcessValue:[{label: data[0].process_name,value: data[0].process_name}]
               
            });
          })
          .catch((error) => {
            alert("Please enter an Active OMID to proceed with the PMM Assessment");
            console.log("Error Encountered");
          });
    
        fetch(
          BaseURL +
            "/Common/GetOMIdProcessDetails1l?OMID=" +
            this.state.OMIDNewValue,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            function getProcessName(val) {
              let processName = [];
              val.forEach((element) => {
                processName.push({
                  value: element.om_ll4_id,
                  label: element.process_name,
                });
              });
              return processName;
            }
           
            this.setState({
              ProcessName: getProcessName(data),
              ProcessValue:getProcessName(data),
            });
            console.log("ProcessName:", this.state.ProcessName);
      console.log("ProcessValue:", this.state.ProcessValue)
          })
    
          .catch((error) => {
            console.log("Error Encountered");
          });
      }
      SelectServiceComponent(e) {
        console.log("e.target.value", e.target.name);
        
        if (e.target.value == "") {
          this.setState({
            defaultProcessName: "",
            eblNxtBTn: false,
            ProcessName: [],
          });
        }
        else {
    console.log("value", this.state.ProcessName.find((item) => item.id == e.target.value))
        this.setState({
          defaultProcessName: e.target.value,
           defaultName : this.state.ProcessName.find((item) => item.id == e.target.value).label,
          eblNxtBTn: true,
        });
      }
    
      }

 
  

  HandleDropdownValues(val) {
    const requestData = {
        ClientNumber: this.state.ClientId,
        UserName: this.props.enterpriseId,
    };

    fetch(`${BaseURL}/Common/GetMainOfferingPost`,
       {
        method: "POST", 
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            console.log(data,"data1");
            const getMainOfferingValue = (data) => {
                return data.map((item) => ({
                    label: item.label,
                    value: item.value,
                }));
            };
            this.setState({ lstMainoffering: getMainOfferingValue(data) });
        })
        .catch((error) => {
            console.error("Error Encountered:", error);
        });
}



SelectDeal(val) {
  console.log(val);
  try {
      $("#loader-div").show(); // Show loader

      // Reset state
      this.setState({
          showComparePopup: false,
          lstServiceComp: [],
          ServiceCompValue: [],
          lstCapability: [],
          CapabilityValue: [],
          lstTower: [],
          TowerValue: [],
          prevAssessmentGridData: [],
          MarketValue: [], // Clear selected market value
          Marketid: null,
          MainOffering: "",
       MainOfferingValue: [],
       Process:[],
        defaultProcessName: [],
        Process_Id: 0,
        ProcessValue:[],
      });

      if (val.length !== 0) {
          const dealValue = val[0].value;
          this.setState({
              DealValue: val,
              ClientId: dealValue,  // Set ClientId to dealValue
              Marketid: dealValue,   // Set Marketid to dealValue
          });

          const marketData = {
              UserName: this.props.enterpriseId,
              ClientNumber: dealValue,  // Use the dealValue to filter market data
              GroupID: this.props.groupId,
              LanguageID: this.props.languageId,
          };

          // Fetch market data
          request("/Common/GetMarket", 'POST', marketData)
              .then(response => {
                  console.log("Market Response:", response);
                  this.setState({
                      lstMarket: response.length > 0 ? response : [], // Update state with relevant data
                  });
              })
              .catch(error => {
                  console.error("Error fetching market data:", error);
              })
              .finally(() => {
                  $("#loader-div").hide(); // Hide loader
              });
      } else {
          this.ClearFilter();
          $("#loader-div").hide(); // Hide loader
      }
  } catch (error) {
      console.error("Error Encountered:", error);
      $("#loader-div").hide(); // Ensure loader is hidden on error
  }
}
SelectMarket(val) {
  
  try {
      $("#loader-div").show(); // Show loader
      console.log("vala",val);
      // Reset state
      this.setState({
          
          prevAssessmentGridData: [],
      });

      if (val.length !== 0) 
        {
          const MarketValue = val[0].value;
          this.setState({
              MarketValue: val,
                // Set ClientId to dealValue
              Marketid: MarketValue,   // Set Marketid to dealValue
          });

          const requestData = {
              UserName: this.props.enterpriseId,
              ClientNumber: this.state.ClientId, // Use the dealValue to filter market data
              
          };

          // Fetch market data
          fetch(`${BaseURL}/Common/GetMainOfferingPost`,
            {
             method: "POST", 
             headers: {
                 Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
                 "Content-Type": "application/json",
             },
             body: JSON.stringify(requestData),
         })
             .then((response) => {
                 if (!response.ok) {
                     throw new Error(`HTTP error! Status: ${response.status}`);
                 }
                 return response.json();
             })
             .then((data) => {
                 console.log(data,"data1");
                 const getMainOfferingValue = (data) => {
                     return data.map((item) => ({
                         label: item.label,
                         value: item.value,
                     }));
                 };
                 this.setState({ lstMainoffering: getMainOfferingValue(data) });
             })
              .catch(error => {
                  console.error("Error fetching market data:", error);
              })
              .finally(() => {
                  $("#loader-div").hide(); // Hide loader
              });
      } else {
          this.ClearFilter();
          $("#loader-div").hide(); // Hide loader
      }
  } catch (error) {
      console.error("Error Encountered:", error);
      $("#loader-div").hide(); // Ensure loader is hidden on error
  }
}
// SelectMarket(val) {
  
//   try {
//       $("#loader-div").show(); // Show loader
//       console.log("vala",val);
//       // Reset state
//       this.setState({
          
//           prevAssessmentGridData: [],
//       });

//       if (val.length !== 0) {
//           const MarketValue = val[0].value;
//           this.setState({
//               MarketValue: val,
//                 // Set ClientId to dealValue
//               Marketid: MarketValue,   // Set Marketid to dealValue
//           });

//           const requestData = {
//               UserName: this.props.enterpriseId,
//               ClientNumber: this.state.ClientId, // Use the dealValue to filter market data
              
//           };

//           // Fetch market data
//           request("/Common/GetMainOfferingPost", 'POST', requestData)
//                       .then((response) => {
//                         if (!response.ok) {
//                             throw new Error(`HTTP error! Status: ${response.status}`);
//                         }
//                         return response.json();
//                     })
//                     .then((data) => {
//                         console.log(data,"data11");
//                         const getMainOfferingValue = (data) => {
//                             return data.map((item) => ({
//                                 label: item.label,
//                                 value: item.value,
//                             }));
//                         };
//                         this.setState({ lstMainoffering: getMainOfferingValue(data) });
//                     })
//               .catch(error => {
//                   console.error("Error fetching market data:", error);
//               })
//               .finally(() => {
//                   $("#loader-div").hide(); // Hide loader
//               });
//       } else {
//           this.ClearFilter();
//           $("#loader-div").hide(); // Hide loader
//       }
//   } catch (error) {
//       console.error("Error Encountered:", error);
//       $("#loader-div").hide(); // Ensure loader is hidden on error
//   }
// }



  async  SelectMainOffering(val) 
 {  
  this.setState({
  lstServiceComp: [],
  ServiceCompValue: [],
  lstCapability: [],
  CapabilityValue: [],
  lstTower: [],
  TowerValue: [],
  ProcessValue:[],
 })
  
    if (val.length  === 0) {
    
      this.setState({
        MainOfferingid: 0,
        mainOfferingName: "",

      })
    }

   
    else{
  await  this.setState({
    MainOfferingid: val[0].value,
      MainOfferingValue:val,
      mainOfferingName: val[0].label,
  
    });
  

    console.log("mainOfferingId2",this.state.mainOfferingId);
   // this.setState({MainOfferingid});
    fetch(
      BaseURL +
        "/Common/GetOMIdOfferingSSG?MainOfferingID=" +
        this.state.MainOfferingid,
  
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("dataa",data)
        function getCapabilityValue(data) {
          return data.map((item) => {
            return {
              label: item.offering_ssg_name,
              value: item.offering_ssg_id,
            };
          });
        }
        this.setState({ lstCapability: getCapabilityValue(data) });
      })
      .catch((error) => {
        console.log("Error Encountered");
      });
    }
  
  }
  async SelectCapability(val) {
    this.setState({
      lstServiceComp: [],
      ServiceCompValue: [],
      
      lstTower: [],
      TowerValue: [],
      ProcessValue:[],
     })
    if (val.length === 0) {
      this.setState({
        Industry_Id: 0,
        capabilityName: "",
      });
    }
    else{
      
    await this.setState({
      Industry_Id: val[0].value,
      capabilityName: val[0].label,
      CapabilityValue:val,
    }
  );
    fetch(
      BaseURL +
        "/Common/GetOMIdSubOffering?OfferingSSGID=" +
        this.state.Industry_Id,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("fet",data);
        function getTowerValue(data) {
          return data.map((item) => {
            return {
              label: item.sub_offering_name,
              value: item.sub_offering_id,
            };
          });
        }
        this.setState({
           lstTower: getTowerValue(data), 
          },
          () =>{console.log("tower",this.state.TowerValue)}
        );
      })
      .catch((error) => {
        console.log("Error Encountered");
      });
     

    }
  }
  async  SelectTower(val) {
    this.setState({
      lstServiceComp: [],
      ServiceCompValue: [],
      ProcessValue:[],
     })
    if (val.length === 0) {
      this.setState({
        subOfferingId: 0,
       });
    }
    else{
    await this.setState({
      subOfferingId: val[0].value,
     TowerValue:val,
    },
  ()=>{
    let processData ={
      SubOfferingIds:this.state.subOfferingId,
      GroupID: this.props.groupId,
     LanguageID: this.props.languageId};

    
     request("/Common/GetProcessBasedOnSubOffering", "POST", processData)
      .then((response) => {
       response.length > 0
         ? this.setState({ ProcessName: response })
         : this.setState({ ProcessName: [] });
       $("#loader-div").hide();
     })
     .catch((error) => {
      console.log("Error Encountered");
      
       $("#loader-div").hide();
     });
  }
  )
  }
    }
// SelectProcess(val) {
//   try {

//       if (val.length != 0) {
//         this.setState({
//           ProcessValue: val,
//           Process_Id:val[0].value,
//           prevAssessmentGridData: [],
//         });
        
//       }
//       else {
//         this.ClearFilter();
//         $("#loader-div").hide();
//       }
//   }
//   catch (error) {
//       console.log("Error Encountered");
//   }
// }
SelectProcess(val) {
  try {
    console.log("Selected Process data:", val);  // Inspect the structure of val

    if (val.length !== 0) {
      console.log("Selected Process value:", val[0].value);  // This should give you the correct value

      // Now set the state
      this.setState(
        {
          ProcessValue: val,
          Process_Id: val[0].value,  // Ensure Process_Id is set correctly
          prevAssessmentGridData: [],
        },
        () => {
          console.log("Process_Id updated:", this.state.Process_Id);
          // If necessary, call GetAssessmentDetails() after state is updated
          this.GetAssessmentDetails();
        }
      );
    } else {
      // If val is empty, clear filters and hide loader
      this.ClearFilter();
      $("#loader-div").hide();
    }
  } catch (error) {
    console.log("Error Encountered:", error);
  }
}




  ClearFilter() {
    try {
      this.setState({
        disableCompare: true,
        lstIndustry: [],
        lstRegion: [],
        lstCapability: [],
        lstTower: [],
        lstServiceComp: [],
        lstDlvryLoc: [],
        DealValue: [],
        MarketValue:[],
        RegionValue: [],
        CountryValue: { label: i18n.t("all"), value: "0" },
        CapabilityValue: [],
        TowerValue: [],
        ServiceCompValue: [],
        prevAssessmentGridData: [],
        SelectMainOffering:"",
        showComparePopup: false,
        OMIDNewValue: "",
        clientName: "",
        Market: "",
        MainOffering: "",
       MainOfferingValue: [],
        Offering_SSG: "",
        SubOffering: "",
        Process:[],
        defaultProcessName: [],
        Process_Id: 0,
        MainOfferingid: 0,
        subOfferingId: 0,
        Industry_Id: 0,
        ClientId:0,
        Marketid: 0,
        OMID: 0,
        ProcessName: [],
        City_Id: 0,
        ProcessValue:[],
          },()=>{this.HandleDropdownValues()});
      recordCount = 0;
    } catch (error) {
      console.log("Error Encountered");
    }
  }


  ShowComparisonPopup() {
    try {
      if (
        this.state.Marketid == 0 &&
        this.state.Marketid.length == 0 
       
      ) {
        if (
          this.state.MainOfferingid == 0  ||
          this.state.MainOfferingid.length == [0]
        ) {
          alert(i18n.t("pleaseSelectCapability"));
          return false;
        }
        if (
          this.state. subOfferingId == 0 ||
          this.state. subOfferingId.length == 0
        ) {
          alert(i18n.t("pleaseSelectTower"));
          return false;
        }
        if (
          this.state.Process_Id == 0 ||
          this.state.Process_Id.length == 0
        ) {
          alert(i18n.t("pleaseSelectServiceComponent"));
          return false;
        }
      }
      var header = {
        Deal: this.state.ClientId,
        Region: this.state.Marketid,
        DeliveryLocation: this.state.City_Id,
        Country: this.state.OMID,
        Capability: this.state.MainOfferingid,
        Tower: this.state.subOfferingId,
        ServiceComponent: this.state.Process_Id,
      };
      this.setState({ headerValue: header });
      this.setState({ showComparePopup: true });
      this.props.propsSelectedAssessmentDetails(header);
      this.props.history.push("/CompareRegionGrid");
    } catch (error) {
      console.log("Error Encountered");
    }
  }

 async DownloadExcel() {
        try {            
            //window.tfo_event("PMM-D-18", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var DealID = this.state.ClientId;
            var RegionID = this.state.Marketid;
            var CountryID = this.state.OMID;
            var CapabilityID = this.state.MainOfferingid;
            var TowerID = this.state.subOfferingId;
            var IndustryID = this.state.Industry_Id;
            var SCID = this.state.Process_Id;
            var SearchedString = "";
            let excelData = {
                "UserName": this.props.enterpriseId,
                "PMMProcessID": 0,
                "DealId": DealID,
                "RegionID": RegionID,
                "CountryID": CountryID,
                "CapabilityId": CapabilityID,
                "TowerID": TowerID,
                "IndustryID": IndustryID,
                "SCId": SCID,
                "SearchedString": SearchedString,
                "PermissionLevel": "3,2,1",
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let jsonexcelData = JSON.stringify(excelData);
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoExcelSubmittedAssessment", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'charset': 'utf-8',
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body : jsonexcelData
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `ProcessMaturityModel_Transaction.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
           
            // await request("/Common/DownloadtoExcelSubmittedAssessment", 'POST', excelData)
            //     .then(response => {
            //         if (response.Table1.length == 0) {
            //             alert(i18n.t("norecordsfound"));
            //         }
            //         else {
            //             var filePath = response.Table1[0].Value;
            //             window.location.href = filePath;
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
  filterCallbackTypeahead(option, props) {
    try {
      if (props.selected.length) {
        return true;
      }

      return (
        option[props.labelKey]
          .toLowerCase()
          .indexOf(props.text.toLowerCase()) !== -1
      );
    } catch (error) {
      console.log("Error Encountered");
    }
  }


  render() {
    return (
      <section className="content">
        {" "}
        <div className="innerpage-hd">
          <ul className="pagecounter">
            <li>
              <span
                title={i18n.t("vIEWPreviousassesssment")}
                className="current"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Landing Page"
              >
                ...
              </span>
            </li>
            <li>
              <span
                title={i18n.t("BOIKeyBusinessMetrics")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="BOI"
              >
                1
              </span>
            </li>
            <li>
              <span
                title={i18n.t("lEADINGINDICATOR")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Leading Indicator "
              >
                2
              </span>
            </li>
            <li>
              <span
                title={i18n.t("pMMASSESSMENT")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="PMM"
              >
                3
              </span>
            </li>
            <li>
              <span
                title={i18n.t("sTORYBOARDGAPASSESSMENT")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Storyboard"
              >
                4
              </span>
            </li>
            <li>
              <span
                title={i18n.t("pOTENTIALPROJECTS")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Potential Projects"
              >
                5
              </span>
            </li>
            <li>
              <span
                title={i18n.t("pROJECTPRIORITIZATION")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Project Prioritization"
              >
                6
              </span>
            </li>
          </ul>

          <Link className="menu2" to="/Home">
            <a type="button" className="btn btn-info btn-back">
              <img src={backArrowIcon} /> {i18n.t("bACKTOHOME")}
            </a>
          </Link>
        </div>
        <section className="bodydata">
          <div className="bodydata-full">
            <div className="box-plain">
              <section className="multiple-tabs-section no-padding-lr">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link className="active" to="/ViewPreviousAssessmentHome">
                      {i18n.t("submittedAssessments")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="" to="/ViewPreviousAssessmentDraft">
                      {i18n.t("SavedDraftAssessments")}
                    </Link>
                  </li>
                  {this.state.isReport != false ? (
                    <li className="nav-item">
                      <Link className="" to="/PMMReports">
                        {i18n.t("reports")}
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </section>
              <h4>{i18n.t("FILTERS")}</h4>
              <div className="box">
                <div className="box-in viewPrj-filter">
                  <span>
                    <div className="form-group col-sm-2">
                      <label id="lbl_OMID">
                        <span style={{ color: "red" }}>*</span>
                        {i18n.t("OMID")}
                      </label>
                      <input
                        ref="ddlOMId"
                        id="ddl_omid"
                        className="form-control"
                        type="text"
                        maxLength="100"
                        value={this.state.OMIDNewValue}
                        onChange={(e) => {
                          this.handleOMIDChange(e);
                        }}
                      />
                      <br />
                      <button
                        className="btn btn-primary"
                        id="btn_search"
                        onClick={this.ValidateOMID.bind(this)}
                      >
                        {i18n.t("OM Search")}
                      </button>
                      <a
                  type="button"
                  className="btn btn-info pull-right"
                  onClick={this.ClearFilter.bind(this)}
                >
                  {i18n.t("clear")}
                </a>

                    </div>
                  </span>

                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_clientBusinessRegion">
                      <span style={{ color: "red" }}>*</span>
                        {i18n.t("Client Name")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')} 
                       id="ddl_Deal_ViewPreviousAssessmentSubmitted"
                        ref="ddlDeal" name="form-field-name" 
                        labelKey="label" 
                        onChange={this.SelectDeal.bind(this)} 
                        options={this.state.lstDeal} 
                        selected={this.state.DealValue}
                         filterBy={this.filterCallbackTypeahead.bind(this)} 
                         placeholder={i18n.t("selectPlace")} />               
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_MarketRegion">
                        {i18n.t("Market")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectMarket.bind(this)}
                    options={this.state.lstMarket} 
                    selected={this.state.MarketValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_capability">
                        {i18n.t("Main Offering")}
                      </label>
                 
                   { console.log("mainoff",this.state.MainOfferingValue)}
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectMainOffering.bind(this)}
                    options={this.state.lstMainoffering} 
                    selected={this.state.MainOfferingValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_capability">
                        {i18n.t("Offering/SSG")}
                      </label>
                     
                     <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectCapability.bind(this)}
                    options={this.state.lstCapability} 
                    selected={this.state.CapabilityValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                   
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_tower">{i18n.t("Sub Offering")}</label>
                     
                      
                     <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectTower.bind(this)}
                    options={this.state.lstTower} 
                    selected={this.state.TowerValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                     <div className="form-group col-md-3">
                      <label id="lbl_serviceComponent">
                        {i18n.t("Process")}
                      </label>
                     
                       <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                   onChange={this.SelectProcess.bind(this)}
                    options={this.state.ProcessName} 
                    selected={this.state.ProcessValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />  
                        
                        
                    </div> 
                  </span>
                </div>
              </div>
              <h4 className="btn-group-md">
                {i18n.t("vIEWPreviousassesssment")}
                <span className="badge">{recordCount}</span>
                <div className="btn-group btn-group-pmmasmt">
                  <button
                    id="btn_downloadListOfAllSubmittedAssessmentsBasedOnYourAccessRights"
                    type="button"
                    className="btn btn-default btn-downld"
                    onClick={this.DownloadExcel.bind(this)}
                    title={i18n.t("Downloadlistallsubmittedassessments")}
                  >
                    <img src={downloadIcon} />
                  </button>
                </div>
                <a
                  type="button"
                  className="btn btn-info pull-right"
                  onClick={this.ClearFilter.bind(this)}
                >
                  {i18n.t("clear")}
                </a>
                <input
                  id="txt_search"
                  type="button"
                  className="btn btn-danger pull-right"
                  value={i18n.t("search")}
                  title={i18n.t("PleaseselectatleastDealName")}
                  onClick={this.GetAssessmentDetails.bind(this)}
                />
                <button
                  id="btn_compare"
                  type="button"
                  className="btn btn-success pull-right"
                  title={i18n.t("selectdealClientBusinessRegionasALL")}
                  onClick={this.ShowComparisonPopup.bind(this)}
                  disabled={this.state.disableCompare}
                >
                  {i18n.t("compare")}
                </button>
              </h4>
              <div className="AsmtData viewprevassmt">
                {this.state.showLoaderFlag == true ? (
                  <div>
                    <center>
                      <img src={loderIcon} />
                    </center>
                  </div>
                ) : (
                  <ViewPreviousAssessmentGrid
                    propsprevAssessmentGridData={
                      this.state.prevAssessmentGridData
                    }
                    propsSelectedAssessmentDetails={
                      this.props.propsSelectedAssessmentDetails
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    languageId: state.languageId,
    enterpriseId: state.enterpriseId,
    groupId: state.groupId,
    groupName: state.groupName,
  };
}
ViewPreviousAssessmentSubmitted = connect(mapStateToProps)(
  withRouter(ViewPreviousAssessmentSubmitted)
);
export default ViewPreviousAssessmentSubmitted;