/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import $ from "jquery";
import backArrowIcon from "../../images/icon-back-arrow.png";
import LandscapeGraph from "./LandscapeGraph";
import request from "../../services/Service";
import { Typeahead } from "react-bootstrap-typeahead";
import i18n from "i18next";
import langKeys from "../../Utils/LangKeys";
import { withTranslation } from "react-i18next";
import { AgGridLocale } from "../../Utils/AgGridLocale";
import { BaseURL } from "../../Constant";

var recordCount = 0;

function createCircelText() {
  try {
    this.eGui = document.createElement("div");
    this.eGui.className = "row";
  } catch (error) {
    console.log("Error Encountered");
  }
}

createCircelText.prototype.init = function (params) {
  try {
    let spanValue = document.createElement("span");
    spanValue.className = "AsIsToBeLabel";
    let circle = document.createElement("div");
    circle.className = "circleDivCompare";
    spanValue.textContent = params.value;
    if (params.data.DealName.trim() != i18n.t("benchmark_CompareScreen")) {
      if (params.value != "" && params.value >= 0 && params.value < 2) {
        circle.style.backgroundColor = "#E57373";
      } else if (params.value != "" && params.value >= 2 && params.value < 3) {
        circle.style.backgroundColor = "#FFB74D";
      } else if (
        params.value != "" &&
        params.value >= 3 &&
        params.value < 3.5
      ) {
        circle.style.backgroundColor = "#FFEB3B";
      } else if (
        params.value != "" &&
        params.value >= 3.5 &&
        params.value <= 4
      ) {
        circle.style.backgroundColor = "#81C784";
      }
      if (params.value != null) {
        this.eGui.appendChild(circle);
        this.eGui.appendChild(spanValue);
      }
    }
  } catch (error) {
    console.log("Error Encountered");
  }
};

createCircelText.prototype.getGui = function (params) {
  try {
    return this.eGui;
  } catch (error) {
    console.log("Error Encountered");
  }
};

class PMMReports extends React.Component {
  constructor(props) {
    super(props);
    try {
      recordCount = 0;
      this.state = {
        prevAssessmentGridData: [],
        disableCompare: true,
        showComparePopup: false,
        headerValue: "",
        lstDeal: [],
        DealValue: [],
        ProcessValue:[],
        lstprocess:[],
        IndustryValue: "",
        lstIndustry: [],
        lstRegion: [],
        MainOfferingValue: [],
        lstMainoffering:[],
        lstCapability: [],
        lstCountry: [],
        lstTower: [],
        lstServiceComp: [],
        lstDlvryLoc: [],
        lstLever: [],
        IndustryValue: "",
        RegionValue: [],
        ClientId:0,
        OGIndustry: "",
        OG: "",
        Industry: "",
        CapabilityValue: [],
        CountryValue: { label: i18n.t("all"), value: "0" },
        TowerValue: [],
        ServiceCompValue: [],
        DlvryLocValue: { label: i18n.t("all"), value: "0" },
        eblNxtBTn: false,
        Confirm: false,
        message: "",
        DraftID: 0,
        comparisionGridData: [],
        popUpLandscapeGraph: false,
        GraphReportArray: [],
        AllOverallScoreArray: [],
        AllStandardizationScoreArray: [],
        AllDealnameArray: [],
        AllRegionArray: [],
        nonEmptyLeverNames: [],
        OMIDNewValue: "",
        clientName: "",
        Market: "",
        MainOffering: "",
        Offering_SSG: "",
        SubOffering: "",
        defaultProcessName: [],
        Process_Id: 0,
        MainOfferingid: 0,
        subOfferingId: 0,
        Industry_Id: 0,
        Marketid: 0,
        Country_Id: 0,
        ProcessName: [],
        City_Id: 0,
      };
      this.HandleDropdownValues = this.HandleDropdownValues.bind(this);
      this.onDealChange = this.onDealChange.bind(this);
      this.SelectMarket =this.SelectMarket.bind(this);
      this.SelectProcess = this.SelectProcess.bind(this);
      this.SelectTower = this.SelectTower.bind(this);
      this.SelectMainOffering = this.SelectMainOffering.bind(this);
      this.reSizeGrid = this.reSizeGrid.bind(this);
      this.closepopUpLandscapeGraph = this.closepopUpLandscapeGraph.bind(this);
      this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
      this.handleOMIDChange = this.handleOMIDChange.bind(this);
      this.ValidateOMID = this.ValidateOMID.bind(this);
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  componentDidMount() {
    this.HandleDropdownValues();
    try {
      //window.tfo_event("PMM-VSA-15", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
      $("#loader-div").show();
      window.addEventListener("resize", this.reSizeGrid, true);


      let marketData ={
        UserName: this.props.enterpriseId,
        ClientNumber:this.state.ClientId,
        GroupID: this.props.groupId,
       LanguageID: this.props.languageId};
       request("/Common/GetMarket", "POST", marketData)
       .then((response) => {
        console.log("exce",response)
         response.length > 0
           ? this.setState({ lstMarket: response })
           : this.setState({ lstMarket: [] });
         $("#loader-div").hide();
       })
       .catch((error) => {
         console.log(error);
         $("#loader-div").hide();
       });

       

      // var DealID = 0;
      // var SCID = 0;
      // var DeliveryLocationId = 0;
      let dealData = {
        DealId: 0,
        PermissionLevel: "3,2,1",
        UserName: this.props.enterpriseId,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/GetDealBasedOnUserAccess", "POST", dealData)
        .then((response) => {
          response.length > 0
            ? this.setState({ lstDeal: response })
            : this.setState({ lstDeal: [] });
          $("#loader-div").hide();
        })
        .catch((error) => {
          console.log(error);
          $("#loader-div").hide();
        });

        let userData = {
          UserName: this.props.enterpriseId,
          GroupID: this.props.groupId,
          LanguageID: this.props.languageId,
        };
        request("/Common/GetReportAccessByUser", "POST", userData)
          .then((response) => {
            response.Table.length > 0
              ? this.setState({ isReport: response.Table[0].IsReport })
              : this.setState({ isReport: "" });
            $("#loader-div").hide();
          })
          .catch((error) => {
            console.log(error);
            $("#loader-div").hide();
          });
        
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  componentWillUnmount() {
    try {
      window.removeEventListener("resize", this.reSizeGrid, true);
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  reSizeGrid() {
    try {
      this.refs.Grd_Comparision.gridOptions.api.sizeColumnsToFit();
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  gridReadyFun(eve) {
    try {
      eve.api.sizeColumnsToFit();
    } catch (error) {
      console.log("Error Encountered");
    }
  }
 
  HandleDropdownValues(val) {
    const requestData = {
        ClientNumber: this.state.ClientId,
        UserName: this.props.enterpriseId,
    };

    fetch(`${BaseURL}/Common/GetMainOfferingPost`,
       {
        method: "POST", 
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            console.log(data,"data1");
            const getMainOfferingValue = (data) => {
                return data.map((item) => ({
                    label: item.label,
                    value: item.value,
                }));
            };
            this.setState({ lstMainoffering: getMainOfferingValue(data) });
        })
        .catch((error) => {
            console.error("Error Encountered:", error);
        });
}
  SelectDeal(val) {
    console.log(val);
    try {
        $("#loader-div").show(); // Show loader
  
        // Reset state
        this.setState({
            showComparePopup: false,
            lstServiceComp: [],
            ServiceCompValue: [],
            lstCapability: [],
            CapabilityValue: [],
            lstTower: [],
            TowerValue: [],
            prevAssessmentGridData: [],
            MarketValue: [], // Clear selected market value
            Marketid: null,
            MainOffering: "",
         MainOfferingValue: [],
         Process:[],
          defaultProcessName: [],
          Process_Id: 0,
          ProcessValue:[],
        });
  
        if (val.length !== 0) {
            const dealValue = val[0].value;
            this.setState({
                DealValue: val,
                ClientId: dealValue,  // Set ClientId to dealValue
                Marketid: dealValue,   // Set Marketid to dealValue
            });
  
            const marketData = {
                UserName: this.props.enterpriseId,
                ClientNumber: dealValue,  // Use the dealValue to filter market data
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId,
            };
  
            // Fetch market data
            request("/Common/GetMarket", 'POST', marketData)
                .then(response => {
                    console.log("Market Response:", response);
                    this.setState({
                        lstMarket: response.length > 0 ? response : [], // Update state with relevant data
                    });
                })
                .catch(error => {
                    console.error("Error fetching market data:", error);
                })
                .finally(() => {
                    $("#loader-div").hide(); // Hide loader
                });
        } else {
            this.ClearFilter();
            $("#loader-div").hide(); // Hide loader
        }
    } catch (error) {
        console.error("Error Encountered:", error);
        $("#loader-div").hide(); // Ensure loader is hidden on error
    }
  }
  SelectMarket(val) {
    
    try {
        $("#loader-div").show(); // Show loader
        console.log("vala",val);
        // Reset state
        this.setState({
            
            prevAssessmentGridData: [],
        });
  
        if (val.length !== 0) 
          {
            const MarketValue = val[0].value;
            this.setState({
                MarketValue: val,
                  // Set ClientId to dealValue
                Marketid: MarketValue,   // Set Marketid to dealValue
            });
  
            const requestData = {
                UserName: this.props.enterpriseId,
                ClientNumber: this.state.ClientId, // Use the dealValue to filter market data
                
            };
  
            // Fetch market data
            fetch(`${BaseURL}/Common/GetMainOfferingPost`,
              {
               method: "POST", 
               headers: {
                   Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
                   "Content-Type": "application/json",
               },
               body: JSON.stringify(requestData),
           })
               .then((response) => {
                   if (!response.ok) {
                       throw new Error(`HTTP error! Status: ${response.status}`);
                   }
                   return response.json();
               })
               .then((data) => {
                   console.log(data,"data1");
                   const getMainOfferingValue = (data) => {
                       return data.map((item) => ({
                           label: item.label,
                           value: item.value,
                       }));
                   };
                   this.setState({ lstMainoffering: getMainOfferingValue(data) });
               })
                .catch(error => {
                    console.error("Error fetching market data:", error);
                })
                .finally(() => {
                    $("#loader-div").hide(); // Hide loader
                });
        } else {
            this.ClearFilter();
            $("#loader-div").hide(); // Hide loader
        }
    } catch (error) {
        console.error("Error Encountered:", error);
        $("#loader-div").hide(); // Ensure loader is hidden on error
    }
  }
  async  SelectMainOffering(val) {
    this.setState({
      lstServiceComp: [],
      ServiceCompValue: [],
      lstCapability: [],
      CapabilityValue: [],
      lstTower: [],
      TowerValue: [],
      ProcessValue:[],
     })
    if (val.length  === 0) {
    
      this.setState({
        MainOfferingid: 0,
        mainOfferingName: "",

      })
    }

   
    else{
  await  this.setState({
    MainOfferingid: val[0].value,
      MainOfferingValue:val,
      mainOfferingName: val[0].label,
  
    });
  

    console.log("mainOfferingId2",this.state.mainOfferingId);
   // this.setState({MainOfferingid});
    fetch(
      BaseURL +
        "/Common/GetOMIdOfferingSSG?MainOfferingID=" +
        this.state.MainOfferingid,
  
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("dataa",data)
        function getCapabilityValue(data) {
          return data.map((item) => {
            return {
              label: item.offering_ssg_name,
              value: item.offering_ssg_id,
            };
          });
        }
        this.setState({ lstCapability: getCapabilityValue(data) });
      })
      .catch((error) => {
        console.log("Error Encountered");
      });
    }
   
  }

  async SelectCapability(val) {
    this.setState({
      lstServiceComp: [],
      ServiceCompValue: [],
      
      lstTower: [],
      TowerValue: [],
      ProcessValue:[],
     })
    if (val.length === 0) {
      this.setState({
        Industry_Id: 0,
        capabilityName: "",
      });
    }
    else{
      
    await this.setState({
      Industry_Id: val[0].value,
      capabilityName: val[0].label,
      CapabilityValue:val,
    }
  );
    fetch(
      BaseURL +
        "/Common/GetOMIdSubOffering?OfferingSSGID=" +
        this.state.Industry_Id,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("fet",data);
        function getTowerValue(data) {
          return data.map((item) => {
            return {
              label: item.sub_offering_name,
              value: item.sub_offering_id,
            };
          });
        }
        this.setState({
           lstTower: getTowerValue(data), 
          },
          () =>{console.log("tower",this.state.TowerValue)}
        );
      })
      .catch((error) => {
        console.log("Error Encountered");
      });
     

    }
  }
  async  SelectTower(val) {
    this.setState({
      lstServiceComp: [],
      ServiceCompValue: [],
      ProcessValue:[],
     })
    if (val.length === 0) {
      this.setState({
        subOfferingId: 0,
       });
    }
    else{
    await this.setState({
      subOfferingId: val[0].value,
     TowerValue:val,
    },
  ()=>{
    let processData ={
      SubOfferingIds:this.state.subOfferingId,
      GroupID: this.props.groupId,
     LanguageID: this.props.languageId};

    
     request("/Common/GetProcessBasedOnSubOffering", "POST", processData)
      .then((response) => {
       response.length > 0
         ? this.setState({ ProcessName: response })
         : this.setState({ ProcessName: [] });
       $("#loader-div").hide();
     })
     .catch((error) => {
      console.log("Error Encountered");
      
       $("#loader-div").hide();
     });
  }
  )
  }
    }
    SelectProcess(val) {
      try {
    
          if (val.length != 0) {
            this.setState({
              ProcessValue: val,
              Process_Id:val[0].value,
              prevAssessmentGridData: [],
            });
            
          }
          else {
            this.ClearFilter();
            $("#loader-div").hide();
          }
      }
      catch (error) {
          console.log("Error Encountered");
      }
    }
  // SelectServiceComponent(val) {
  //     try {
  //         this.setState({ ServiceCompValue: val });
  //         this.setState({ showComparePopup: false });
  //     }
  //     catch (error) {
  //         console.log("Error Encountered");
  //     }
  // }

  SelectDlvryLoc(val) {
    try {
      this.setState({ DlvryLocValue: val });
      this.setState({
        showComparePopup: false,
        lstServiceComp: [],
        ServiceCompValue: [],
        lstCapability: [],
        CapabilityValue: [],
        lstTower: [],
        TowerValue: [],
      });
      if (val != null) {
        let capabilityData = {
          ID: 0,
          DealId: this.state.DealValue[0].value,
          DeliveryLocationId: val[0].value,
          UserName: this.props.enterpriseId,
          GroupID: this.props.groupId,
          LanguageID: this.props.languageId,
        };
        request("/Common/GetCapabilityBasedOnDeal", "POST", capabilityData)
          .then((response) => {
            this.setState({ lstCapability: response });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        $("#loader-div").hide();
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }
  handleOMIDChange(e) {
    if (e.target.value == "") {
      this.setState({
        OMIDNewValue: "",
        clientName: "",
        Market: "",

        MainOffering: "",
        Offering_SSG: "",
        SubOffering: "",
        defaultProcessName: [],
        ProcessName:"",
            lstprocess:[],
            ProcessValue:"",
      });
    } else {
      this.setState({
        OMIDNewValue: e.target.value,
      });
    }
  }

  ValidateOMID() {
    fetch(
      BaseURL + "/Common/GetOMIdDetails1l?OMID=" + this.state.OMIDNewValue,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          clientName: data[0].client_name_1,
          Market: data[0].market_name,
          MainOffering: data[0].main_offering_name,
          Offering_SSG: data[0].offering_ssg_name,
          SubOffering: data[0].sub_offering_name,
          City_Id: data[0].city_id,
          ClientId:data[0].client_number,
          Marketid: data[0].market_id,
          OMID: data[0].om_master_id,
          Process_Id: data[0].taxonomy_id,
          MainOfferingid: data[0].main_offering_id,
          subOfferingId: data[0].sub_offering_id,
          Industry_Id: data[0].offering_ssg_id,
          DealValue: [{label: data[0].client_name_1,value: data[0].client_name_1}],
          MarketValue:[{label: data[0].market_name,value: data[0].market_name}],
           MainOfferingValue:[{label: data[0].main_offering_name,value: data[0].main_offering_name}],
           CapabilityValue:[{label: data[0].offering_ssg_name,value: data[0].offering_ssg_name}],
           TowerValue:[{label: data[0].sub_offering_name,value: data[0].sub_offering_name}],
           ProcessValue:[{label: data[0].process_name,value: data[0].process_name}]
        });
      })
      .catch((error) => {
        alert("Please enter an Active OMID to proceed with the PMM Assessment");
        console.log("Error Encountered");
      });

    fetch(
      BaseURL +
        "/Common/GetOMIdProcessDetails1l?OMID=" +
        this.state.OMIDNewValue,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        function getProcessName(val) {
          let processName = [];
          val.forEach((element) => {
            processName.push({
              id: element.taxonomy_id,
              label: element.process_name,
            });
          });
          return processName;
        }
        this.setState({
          ProcessName: getProcessName(data),
          ProcessValue:getProcessName(data),
        });
      })

      .catch((error) => {
        console.log("Error Encountered");
      });
  }
  SelectServiceComponent(e) {
    console.log("e.target.value", e.target.name);

    if (e.target.value == "") {
      this.setState({
        defaultProcessName: "",
        eblNxtBTn: false,
        ProcessName: [],
      });
    } else {
      console.log(
        "value",
        this.state.ProcessName.find((item) => item.id == e.target.value)
      );
      this.setState({
        defaultProcessName: e.target.value,
        defaultName: this.state.ProcessName.find(
          (item) => item.id == e.target.value
        ).label,
        eblNxtBTn: true,
      });
    }
  }

  ClearFilter() {
    try {
      this.setState({
        disableCompare: true,
        lstIndustry: [],
        lstRegion: [],
        lstCapability: [],
        lstTower: [],
        lstServiceComp: [],
        lstDlvryLoc: [],
        DealValue: [],
        MarketValue:[],
        RegionValue: [],
        CountryValue: { label: i18n.t("all"), value: "0" },
        CapabilityValue: [],
        TowerValue: [],
        ServiceCompValue: [],
        SelectMainOffering:"",
        prevAssessmentGridData: "",
        showComparePopup: false,
        comparisionGridData: [],
        OMIDNewValue: "",
        clientName: "",
        Market: "",
        MainOffering: "",
        MainOfferingValue: [],
        Offering_SSG: "",
        SubOffering: "",
        defaultProcessName: [],
        Process_Id: 0,
        MainOfferingid: 0,
        subOfferingId: 0,
        Industry_Id: 0,
        ClientId:0,
        Marketid: 0,
        OMID: 0,
        ProcessName: [],
        City_Id: 0,
        ProcessValue:[],
          },()=>{this.HandleDropdownValues()});
      recordCount = 0;
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  ShowComparisonPopup() {
    try {
      if (this.state.Marketid == 0 && this.state.Marketid.length == 0) {
        if (
          this.state.MainOfferingid == 0 ||
          this.state.MainOfferingid.length == [0]
        ) {
          alert(i18n.t("pleaseSelectCapability"));
          return false;
        }
        if (this.state.subOfferingId == 0 || this.state.subOfferingId.length == 0) {
          alert(i18n.t("pleaseSelectTower"));
          return false;
        }
        if (this.state.Process_Id == 0 || this.state.Process_Id.length == 0) {
          alert(i18n.t("pleaseSelectServiceComponent"));
          return false;
        }
      }
      $("#loader-div").show();
      // if(!this.state.DealValue[0].label.toLowerCase().includes("test")){
      // window.tfo_dataevent("PMM-VSA-22", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"],1,this.state.DealValue[0].label)
      // }
      var SearchedString = "";
      let viewDealWiseData = {
        DealID: 0,
        RegionID: this.state.Marketid,
        DeliveryLocationId: this.state.City_Id,
        SCID: this.state.Process_Id,
        SearchedString: SearchedString,
        UserName: this.props.enterpriseId,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      Promise.all([
        request("/PMM/ViewDealWiseComparison", "POST", viewDealWiseData),
        request(
          "/MasterScreens/GetLever?LeverID=0&GroupID=" +
            this.props.groupId +
            "&SearchedString=" +
            SearchedString +
            "&LanguageID=" +
            this.props.languageId,
          "GET"
        ),
      ])

        .then((response) => {
          if (response[0]) {
            if (response[1]) {
              var emptyLevers = [];
              response[1].map((lever, leverindex) => {
                var levername = lever.LeverName;
                var empty = response[0].Table.filter(
                  (item) =>
                    item[levername] !== undefined &&
                    item[levername] != null &&
                    item[levername] != "" &&
                    item.DealName.trim() != i18n.t("benchmark_CompareScreen")
                );
                if (empty.length == 0) {
                  emptyLevers.push(levername);
                }
              });
              var nonEmptyLevers = response[1].filter(
                (item) => !emptyLevers.includes(item.LeverName)
              );
              if (response[0].Table[0].OutputCode == 1) {
                this.setState({
                  comparisionGridData: null,
                  nonEmptyLeverNames: nonEmptyLevers,
                });
              } else {
                this.setState({
                  comparisionGridData: response[0].Table,
                  nonEmptyLeverNames: nonEmptyLevers,
                });
              }
            } else {
              this.setState({
                comparisionGridData: response[0].Table,
                nonEmptyLeverNames: [],
              });
            }
          }
          this.reSizeGrid();
          $("#loader-div").hide();
        })
        .catch((error) => {
          $("#loader-div").hide();
          console.log("Error Encountered");
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }
  ShowLandscapegraph(e) {
    try {
      if (this.state.Marketid == 0 && this.state.Marketid.length == 0) {
        if (
          this.state.MainOfferingid == 0 ||
          this.state.MainOfferingid.length == [0]
        ) {
          alert(i18n.t("pleaseSelectCapability"));
          return false;
        }
        if (this.state.subOfferingId == 0 || this.state.subOfferingId.length == 0) {
          alert(i18n.t("pleaseSelectTower"));
          return false;
        }
        if (this.state.Process_Id == 0 || this.state.Process_Id.length == 0) {
          alert(i18n.t("pleaseSelectServiceComponent"));
          return false;
        }
      }
      // if(!this.state.DealValue[0].label.toLowerCase().includes("test")){
      // window.tfo_dataevent("PMM-VSA-21", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"],1,this.state.DealValue[0].label)
      // }
      var SearchedString = "";
      let viewDealWiseData = {
        DealID: 0,
        RegionID: this.state.Marketid,
        DeliveryLocationId: this.state.City_Id,
        SCID: this.state.Process_Id,
        SearchedString: SearchedString,
        UserName: this.props.enterpriseId,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/PMM/ViewDealWiseComparison", "POST", viewDealWiseData)
        .then((response) => {
          let GraphArrayvalues = [];
          if (response) {
            if (response.Table[0].OutputCode == 1) {
              GraphArrayvalues = [];
            } else {
              GraphArrayvalues = response.Table.filter((i) => {
                if (
                  i.DealName.trim() != i18n.t("benchmark_CompareScreen") &&
                  i.Standardization != null
                ) {
                  return i;
                }
              });
            }
          }
          this.setState({
            AllOverallScoreArray: GraphArrayvalues.map((i) => {
              return i.OverAll;
            }),
            AllStandardizationScoreArray: GraphArrayvalues.map((i) => {
              return i.Standardization;
            }),
            AllDealnameArray: GraphArrayvalues.map((i) => {
              return i.DealName;
            }),
            AllRegionArray: GraphArrayvalues.map((i) => {
              return i.RegionName;
            }),
            popUpLandscapeGraph: true,
          });
          $("#loader-div").hide();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }
  closepopUpLandscapeGraph() {
    try {
      this.setState({
        popUpLandscapeGraph: false,
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  }
  async DownloadtoExcelProjectPrioritization() {
    try {
        var DealID = this.state.DealValue[0].value == undefined ? 0 : this.state.DealValue[0].value;
        var RegionID = this.state.RegionValue[0].value == undefined ? 0 : this.state.RegionValue[0].value;
        var TowerID = this.state.TowerValue[0].value == undefined ? 0 : this.state.TowerValue[0].value;
        var SCID = this.state.ServiceCompValue[0].value == undefined ? 0 : this.state.ServiceCompValue[0].value;
        var DeliveryLocationID = this.state.DlvryLocValue[0].value == undefined ? 0 : this.state.DlvryLocValue[0].value;
        var SearchedString = "";
        let projData = {
            "DealId": DealID,
            "RegionID": RegionID,
            "TowerID": TowerID,
            "SCId": SCID,
            "DeliveryLocationId": DeliveryLocationID,
            "SearchedString": SearchedString,
            "UserName": this.props.enterpriseId,
      "GroupID": this.props.groupId,
      "LanguageID":this.props.languageId
        }
        let jsonprojData = JSON.stringify(projData);
        $("#loader-div").show();
        fetch(BaseURL + "/Common/DownloadtoExcelProjectPrioritization", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'charset': 'utf-8',
                "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
            },
            body : jsonprojData
        })
        .then(response => response.blob())
            .then((blob) => {
                const BlobUrl = window.URL.createObjectURL(blob);
                var downLink = document.createElement("a");
                downLink.href = BlobUrl;
                downLink.download = `PrioritizedProject.xlsx`;
                document.body.appendChild(downLink);
                downLink.click();
                downLink.remove();
                $("#loader-div").hide();
                alert(i18n.t("filedownloaded"));
                }
            )
       
        // await request("/Common/DownloadtoExcelProjectPrioritization", 'POST', projData)
        //     .then(response => {
        //         var filePath = response.Table1[0].Value;
        //         window.location.href = filePath;
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

  ShowBOILITrendReport() {
    try {
      // if(this.state.DealValue.length !== 0){
      //     if(!this.state.DealValue[0].label.toLowerCase().includes("test")){
      //     window.tfo_dataevent("PMM-VSA-23", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"],1,this.state.DealValue[0].label)
      //     }
      // }
      // else{
      // window.tfo_event("PMM-VSA-23", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
      // }
      this.props.history.push("/BOILITrendReport");
    } catch (error) {
      console.log("Error Encountered");
    }
  }
  filterCallbackTypeahead(option, props) {
    try {
      if (props.selected.length) {
        return true;
      }
      return (
        option[props.labelKey]
          .toLowerCase()
          .indexOf(props.text.toLowerCase()) !== -1
      );
    } catch (error) {
      console.log("Error Encountered");
    }
  }
  render() {
    var columnDefs = [];

    var DealNameColDef = {
      headerName: i18n.t("Client Name"),
      field: "DealName",
      tooltipField: "DealName",
      suppressMovable: true,
      suppressResize: true,
      autoHeight: true,
      cellStyle: function (params) {
        if (
          params.node.data.DealName.trim() == i18n.t("benchmark_CompareScreen")
        ) {
          let backgroundVal = "rgba(255, 183, 76, 1)";
          return {
            background: backgroundVal,
            "font-size": "16px",
            "font-weight": "bold",
          };
        }
      },
    };
    var OverAllColDef = {
      headerName: i18n.t("overall"),
      field: "OverAll",
      tooltipField: "OverAll",
      suppressMovable: true,
      suppressResize: true,
      autoHeight: true,
      cellRenderer: createCircelText,
    };
    var PolicyColDef = {
      headerName: i18n.t("policy"),
      field: "Policy",
      tooltipField: "Policy",
      suppressMovable: true,
      suppressResize: true,
      autoHeight: true,
      cellRenderer: createCircelText,
    };
    var PracticeColDef = {
      headerName: i18n.t("practice"),
      field: "Practice",
      tooltipField: "Practice",
      suppressMovable: true,
      suppressResize: true,
      autoHeight: true,
      cellRenderer: createCircelText,
    };
    var PlatformColDef = {
      headerName: i18n.t("platform"),
      field: "Platform",
      tooltipField: "Platform",
      suppressMovable: true,
      suppressResize: true,
      autoHeight: true,
      cellRenderer: createCircelText,
    };
    var StandardizationColDef = {
      headerName: i18n.t("standardization"),
      field: "Standardization",
      tooltipField: "Standardization",
      suppressMovable: true,
      suppressResize: true,
      autoHeight: true,
      cellRenderer: createCircelText,
    };

    let dynamicLever = [];
    this.state.lstLever.forEach((obj, index) => {
      return (dynamicLever[index] = {
        headerName: obj,
        field: obj,
        tooltipField: obj,
        suppressMovable: true,
        suppressResize: true,
        autoHeight: true,
        cellRenderer: createCircelText,
      });
    });

    if (this.state.comparisionGridData) {
      if (this.state.nonEmptyLeverNames.length > 0) {
        var LeverColDefs = [];
        this.state.nonEmptyLeverNames.forEach((item, index) => {
          var LeverColDef = {
            headerName: item.LeverName,
            field: item.LeverName,
            tooltipField: item.LeverName,
            suppressMovable: true,
            suppressResize: true,
            autoHeight: true,
            cellRenderer: createCircelText,
          };
          return LeverColDefs.push(LeverColDef);
        });
        columnDefs.push(DealNameColDef, OverAllColDef);
        LeverColDefs.forEach((item, index) => {
          return columnDefs.push(item);
        });
        columnDefs.push(StandardizationColDef);
      } else {
        columnDefs = [DealNameColDef, OverAllColDef];
        dynamicLever.forEach((obj) => {
          return columnDefs.push(obj);
        });
        columnDefs.push(StandardizationColDef);
      }
    } else {
      columnDefs = [DealNameColDef, OverAllColDef];
      dynamicLever.forEach((obj) => {
        return columnDefs.push(obj);
      });
      columnDefs.push(StandardizationColDef);
    }

    var comparisionGridData = this.state.comparisionGridData;

    var ComparisonDataGrid = (
      <div style={{ height: "450px" }} className="ag-theme-material padding5">
        <AgGridReact
          ref="Grd_Comparision"
          id="grd_Comparision"
          paginationPageSize="10"
          enableColResize={false}
          columnDefs={columnDefs}
          rowData={comparisionGridData}
          headerHeight="48"
          gridAutoHeight={true}
          onGridReady={this.gridReadyFun}
          rowmodeltype="pagination"
          localeText={AgGridLocale()}
          overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>'
        />
      </div>
    );
    return (
      <section className="content">
        <div className="innerpage-hd">
          <ul className="pagecounter">
            <li>
              <span
                title={i18n.t("vIEWPreviousassesssment")}
                className="current"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Landing Page"
              >
                ...
              </span>
            </li>
            <li>
              <span
                title={i18n.t("BOIKeyBusinessMetric")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="BOI"
              >
                1
              </span>
            </li>
            <li>
              <span
                title={i18n.t("lEADINGINDICATOR")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Leading Indicator "
              >
                2
              </span>
            </li>
            <li>
              <span
                title={i18n.t("pMMASSESSMENT")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="PMM"
              >
                3
              </span>
            </li>
            <li>
              <span
                title={i18n.t("sTORYBOARDGAPASSESSMENT")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Storyboard"
              >
                4
              </span>
            </li>
            <li>
              <span
                title={i18n.t("pOTENTIALPROJECTS")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Potential Projects"
              >
                5
              </span>
            </li>
            <li>
              <span
                title={i18n.t("pROJECTPRIORITIZATION")}
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Project Prioritization"
              >
                6
              </span>
            </li>
          </ul>

          <Link className="menu2" to="/Home">
            <a type="button" className="btn btn-info btn-back">
              <img src={backArrowIcon} /> {i18n.t("bACKTOHOME")}
            </a>
          </Link>
        </div>
        <section className="bodydata">
          <div className="bodydata-full">
            <div className="box-plain">
              <section className="multiple-tabs-section no-padding-lr">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link className="" to="/ViewPreviousAssessmentHome">
                      {i18n.t("submittedAssessments")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="" to="/ViewPreviousAssessmentDraft">
                      {i18n.t("SavedDraftAssessments")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="active" to="/PMMReports">
                      {i18n.t("reports")}
                    </Link>
                  </li>
                </ul>
              </section>
              <h4>{i18n.t("FILTERS")}</h4>
              <div className="box">
                <div className="box-in viewPrj-filter">
                  <span>
                    <div className="form-group col-sm-2">
                      <label id="lbl_OMID">
                        <span style={{ color: "red" }}>*</span>
                        {i18n.t("OMID")}
                      </label>

                      {/* <ReactSelect ref="ddlDeal" id="ddl_Deal_ViewPreviousAssessmentSubmitted" name="form-field-name" options={this.state.lstDeal} onChange={this.SelectDeal.bind(this)} value={this.state.DealValue} clearable={false} /> */}
                      <input
                        ref="ddlOMId"
                        id="ddl_omid"
                        className="form-control"
                        type="text"
                        maxLength="100"
                        value={this.state.OMIDNewValue}
                        onChange={(e) => {
                          this.handleOMIDChange(e);
                        }}
                      />
                      <br />
                      <button
                        className="btn btn-primary"
                        id="btn_search"
                        onClick={this.ValidateOMID.bind(this)}
                      >
                        {i18n.t("OM Search")}
                      </button>
                      <a
                  type="button"
                  className="btn btn-info pull-right"
                  onClick={this.ClearFilter.bind(this)}
                >
                  {i18n.t("clear")}
                </a>

                    </div>
                  </span>

                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_clientBusinessRegion">
                        {i18n.t("Client Name")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')} 
                       id="ddl_Deal_ViewPreviousAssessmentSubmitted"
                        ref="ddlDeal" name="form-field-name" 
                        labelKey="label" 
                        onChange={this.SelectDeal.bind(this)} 
                        options={this.state.lstDeal} 
                        selected={this.state.DealValue}
                         filterBy={this.filterCallbackTypeahead.bind(this)} 
                         placeholder={i18n.t("selectPlace")} /> 
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_clientBusinessRegion">
                        {i18n.t("Market")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectMarket.bind(this)}
                    options={this.state.lstMarket} 
                    selected={this.state.MarketValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_capability">
                        {i18n.t("Main Offering")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectMainOffering.bind(this)}
                    options={this.state.lstMainoffering} 
                    selected={this.state.MainOfferingValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_capability">
                        {i18n.t("Offering/SSG")}
                      </label>
                     
                     <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectCapability.bind(this)}
                    options={this.state.lstCapability} 
                    selected={this.state.CapabilityValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                   
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_tower">{i18n.t("Sub Offering")}</label>
                     
                      
                     <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectTower.bind(this)}
                    options={this.state.lstTower} 
                    selected={this.state.TowerValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                     <div className="form-group col-md-2">
                      <label id="lbl_serviceComponent">
                        {i18n.t("Process")}
                      </label>
                     
                       <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                   onChange={this.SelectProcess.bind(this)}
                    options={this.state.ProcessName} 
                    selected={this.state.ProcessValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />  
                        
                        
                    </div> 
                  </span>
                </div>
              </div>
              <h4 className="btn-group-md">
                {i18n.t("viewClientWiseComparision")}
                <a
                  type="button"
                  className="btn btn-info pull-right"
                  onClick={this.ClearFilter.bind(this)}
                >
                  {i18n.t("clear")}
                </a>
                <input
                  id="txt_BOI/LI_TrendReport"
                  type="button"
                  className="btn btn-danger pull-right"
                  value={i18n.t("BOILITrendR")}
                  title={i18n.t("PleaseselectatleastClientName")}
                  onClick={this.ShowBOILITrendReport.bind(this)}
                />
                <button
                  id="btn_compareDealScores"
                  type="button"
                  className="btn btn-success pull-right"
                  title={i18n.t("selectdealClientBusinessRegionasALL")}
                  onClick={this.ShowComparisonPopup.bind(this)}
                  disabled={this.state.disableCompare}
                >
                  {i18n.t("compareClientScores")}
                </button>
                <button
                  id="btn_stdScoreGraph"
                  type="button"
                  className="btn btn-graph pull-right"
                  onClick={this.ShowLandscapegraph.bind(this)}
                  disabled={this.state.disableCompare}
                >
                  {i18n.t("StdScoreGraph")}
                </button>
              </h4>
              <div className="AsmtData viewprevassmt">{ComparisonDataGrid}</div>
              {this.state.popUpLandscapeGraph && (
                <LandscapeGraph
                  show={this.state.popUpLandscapeGraph}
                  closeCallback={this.closepopUpLandscapeGraph}
                  asToValX={this.state.AllOverallScoreArray}
                  asToValY={this.state.AllStandardizationScoreArray}
                  XLabel={this.state.AllDealnameArray}
                  YLabel={this.state.AllRegionArray}
                  DealName={this.state.DealValue[0].label}
                  ServiceComponentName={this.state.ServiceCompValue[0].label}
                />
              )}
            </div>
          </div>
        </section>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropDownsValue: state.dropDownsValue,
    languageId: state.languageId,
    enterpriseId: state.enterpriseId,
    groupId: state.groupId,
    roleName: state.roleName,
    pageName: state.pageName,
    groupName: state.groupName,
  };
}

PMMReports = connect(mapStateToProps)(withRouter(PMMReports));
export default PMMReports;